import React from "react";
import Layout from "../../../components/layout";
import { Container, Row, Col, Carousel } from "react-bootstrap";
import Seo from "../../../components/seo";
import { StaticImage } from "gatsby-plugin-image";
//Images
import SliderOne from "./slider-1-100.jpg"
import SliderTwo from "./slider-2-100.jpg"
import SliderThree from "./slider-3-100.jpg"
// Data Source
import JSONData from "../../../product-json/product-100-BDS0029.json"
import ProductDataComponentPartOne from "../../../components/productDataDisplayPartOne";
import ProductQuoteConfigure from "../../../components/productQuoteConfigure";
import ProductDataComponentPartTwo from "../../../components/productDataDisplayPartTwo";

const Product99 = () => {
    return (
        <div className="product">
        <Seo
            title={JSONData.title}
            description={JSONData.description.substring(0, 180)}
        />
        <Layout>
            <Carousel slide fade interval={2000} controls={false}>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={SliderOne}
                        alt={JSONData.title}
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={SliderTwo}
                        alt={JSONData.title}
                    />
                </Carousel.Item>
                <Carousel.Item>
                    <img
                        className="d-block w-100"
                        src={SliderThree}
                        alt={JSONData.title}
                    />
                </Carousel.Item>
            </Carousel>
            <Container>
                <Row>
                    <Col sm="12" className="text-center">
                        <h1 className="mt-5 mb-4 uppercase">
                            {JSONData.title}
                        </h1>
                    </Col>
                </Row>
                <Row>
                    <Col sm="12" lg="6" className="text-center">
                        <StaticImage placeholder="blurred"  src="./main-100.jpg" className="shadow mb-4 productImage fluid" alt={JSONData.title} />
                    </Col>
                    <ProductQuoteConfigure JSONData={JSONData} />
                </Row>
                <ProductDataComponentPartOne JSONData={JSONData} />

            </Container>
            <Row className="mb-3">
                <Col sm="12" lg="6">
                <StaticImage placeholder="blurred"  src="./palette1-100.jpg" alt={JSONData.title} className="min-full fluid"/>
                <StaticImage placeholder="blurred"  src="./palette2-100.jpg" alt={JSONData.title} className="min-full fluid"/>
                </Col>
                <Col sm="12" lg="6">
                <StaticImage placeholder="blurred"  src="./palette3-100.jpg" alt={JSONData.title} className="min-full fluid"/>
                </Col>
            </Row>
            <Container>
                <Row>
                    <ProductDataComponentPartTwo JSONData={JSONData} />
                    <hr />
                    <Col sm="12" className="text-center">
                    <StaticImage placeholder="blurred"  src="./plan-100.jpg" alt={JSONData.title} className="fluid"/>
                    </Col>
                </Row>
            </Container>
        </Layout>
    </div>
    )
}

export default Product99;